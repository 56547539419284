@font-face {
  font-family: "Source Sans Pro";
  src: url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.woff2")
      format("woff2"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.woff")
      format("woff"),
    url("./assets/fonts/SourceSansPro/SourceSansPro-Regular.ttf")
      format("truetype");
  font-weight: normal;
  font-style: normal;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html,
body,
#root {
  height: 100%;
}

body {
  background: #f5f5f5;
  font-family: "Source Sans Pro", system-ui, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-display: swap;
  letter-spacing: 0;
  color: #757575;
  position: relative;
}

img {
  border: none;
}
img,
object,
embed,
video {
  max-width: 100%;
}
.ie6 img {
  width: 100%;
}

h1 {
  font-size: 2rem;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
h2 {
  font-size: 1.5rem;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
h3 {
  font-size: 1.17rem;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
h4 {
  font-size: 1rem;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
h5 {
  font-size: 0.83rem;
  font-family: "Source Sans Pro";
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}
h6 {
  font-size: 0.67rem;
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-style: normal;
}

p {
  margin: 0.5em 0;
  padding: 0;
  text-align: left;
}

strong {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-style: normal;
}
i {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-style: italic;
}
a:link {
  color: inherit;
  text-decoration: underline;
}
a:hover {
  color: #37474f;
}
a:visited {
  color: inherit;
}

.container {
  width: 90%;
  margin: 0 auto !important;
  padding: 0.5em;
  display: flex;
  flex-wrap: wrap;
}

.close {
  display: none;
}

.animation {
  visibility: hidden;
}

.fade {
  animation-name: fade;
  animation-duration: 1s;
  transition: 2s;
  visibility: visible !important;
  z-index: 99;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.preload {
  width: 100%;
  height: 98vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(112, 112, 112, 0.54);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: rgba(112, 112, 112, 0.54);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }

  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }

  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@media only screen and (min-width: 769px) {
  .container {
    width: 100%;
    max-width: 1216px;
  }

  .close {
    display: flex;
    flex-wrap: wrap;
    align-self: flex-end;
  }

  .closeSub {
    display: none;
    max-height: 0;
  }
  .fadeSub {
    animation-name: sub;
    animation-duration: 1s;
    max-height: 100%;
    transition: 2s;
    visibility: visible !important;
    display: block !important;
  }

  @keyframes sub {
    from {
      max-height: 0;
      opacity: 0;
    }

    to {
      max-height: 100%;
      opacity: 1;
    }
  }
}
